export const DEFAULT_TOOLTIP = {
    offsetX: 20,
    offsetY: 5,
    type: 'white',
    templateHtml: () => ''
};
export const DEFAULT_TOOLTIP_ASIDE = {
    ...DEFAULT_TOOLTIP,
    templateHtml: () => '',
    yLine: undefined,
    alignCenter: undefined
};
export const DEFAULT_TOOLTIP_FOLLIWING = {
    ...DEFAULT_TOOLTIP,
    templateHtml: () => '',
    insideBoxMode: true,
    type: 'black'
};
