import { ref } from 'vue-demi';
import { debounce } from '../utils';
export default function useObserverResize(dom) {
    const contentRect = ref({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    });
    const debounceResize = debounce((entries) => {
        const entry = entries[0];
        contentRect.value = entry.contentRect;
    });
    const observer = new ResizeObserver((entries, observer) => {
        // 初始值不做emit
        // if (isMounted) {
        debounceResize(entries);
        // }
    });
    observer.observe(dom);
    return contentRect;
}
