import * as d3 from 'd3';
import Tooltip from './Tooltip';
import { getHtmlElementSize } from '../d3Utils';
import { DEFAULT_TOOLTIP_FOLLIWING } from './defaults';
export default class TooltipFollowing extends Tooltip {
    tooltipSelection = undefined;
    constructor(selection, params) {
        super(selection, params);
        // this.params = {
        //   ...this.params,
        //   ...params
        // }
        // const insideBoxMode = params?.insideBoxMode ?? true
        // if (insideBoxMode === true) {
        //   this.renderTooltip = this.renderInsideBox
        // } else {
        //   this.renderTooltip = this.renderDefault
        // }
        this.setParams(params ?? this.params);
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params
        };
        const insideBoxMode = params?.insideBoxMode ?? true;
        if (insideBoxMode === true) {
            this.renderTooltip = this.renderInsideBox;
        }
        else {
            this.renderTooltip = this.renderDefault;
        }
    }
    // 依座標顯示
    renderDefault(renderData) {
        try {
            this.tooltipSelection.interrupt('fadeout');
        }
        catch (e) { }
        const containerSize = getHtmlElementSize(this.selection);
        const parentNode = this.selection.node().parentNode;
        // const update = d3.select('body')
        const update = d3.select(parentNode)
            .selectAll('.bpchat___tooltip')
            .data(renderData);
        const enter = update.enter()
            .append('div')
            .classed('bpchat___tooltip', true)
            .call(this.setTooltipStyle);
        this.tooltipSelection = update.merge(enter)
            .style('position', 'absolute')
            .style('z-index', 10000)
            .style('left', (d) => {
            const x = d.x - containerSize.x;
            return `${x + this.params.offsetX}px`;
        })
            .style('top', (d) => {
            const y = d.y - containerSize.x;
            return `${y + this.params.offsetY}px`;
        })
            .html((d) => d.contentHtml)
            .on('interrupt', (d, i, g) => {
            d3.select(g[i]).style('opacity', 1);
        });
        update.exit()
            .transition('fadeout')
            .style('opacity', 0)
            .remove()
            .on('interrupt', (d, i, g) => {
            d3.select(g[i]).style('opacity', 1);
        });
    }
    // 座標限制在元素內
    renderInsideBox(renderData) {
        try {
            this.tooltipSelection.interrupt('fadeout');
        }
        catch (e) { }
        const containerSize = getHtmlElementSize(this.selection);
        const tooltipSize = (this.tooltipSelection?.node()) ? getHtmlElementSize(this.tooltipSelection) : { width: 0, height: 0 };
        // const minX = containerSize.x
        // const maxX = containerSize.x + containerSize.width - tooltipSize.width
        // const minY = containerSize.y
        // const maxY = containerSize.y + containerSize.height - tooltipSize.height
        const minX = 0;
        const maxX = containerSize.width - tooltipSize.width;
        const minY = 0;
        const maxY = containerSize.height - tooltipSize.height;
        const parentNode = this.selection.node().parentNode;
        // const update = d3.select('body')
        const update = d3.select(parentNode)
            .selectAll('.bpchat___tooltip')
            .data(renderData);
        const enter = update.enter()
            .append('div')
            .classed('bpchat___tooltip', true)
            .call(this.setTooltipStyle);
        this.tooltipSelection = update.merge(enter)
            .style('position', 'absolute')
            .style('z-index', 10000)
            .style('left', (d) => {
            const x = d.x + this.params.offsetX - containerSize.x;
            if (x < minX) {
                return `${minX}px`;
            }
            else if (x > maxX) {
                return `${maxX}px`;
            }
            return `${x}px`;
        })
            .style('top', (d) => {
            const y = d.y + this.params.offsetY - containerSize.y;
            if (y < minY) {
                return `${minY}px`;
            }
            else if (y > maxY) {
                return `${maxY}px`;
            }
            return `${y}px`;
        })
            .html((d) => d.contentHtml);
        update.exit()
            .transition('fadeout')
            .style('opacity', 0)
            .remove()
            .on('interrupt', (d, i, g) => {
            d3.select(g[i]).style('opacity', 1);
        });
    }
    renderTooltip(data) { }
    remove() {
        if (this.tooltipSelection) {
            this.tooltipSelection
                .transition('fadeout')
                .style('opacity', 0)
                .remove()
                .on('interrupt', (d, i, g) => {
                d3.select(g[i]).style('opacity', 1);
            });
            // .on('end', () => {
            //   this.renderData.contentHtml = ''
            // })
        }
    }
}
