import { defineComponent, ref, computed, onMounted, watch, useAttrs, isVue2, isVue3 } from 'vue-demi';
import useD3Module from '../composables/useD3Module';
import useObserverResize from '../composables/useObserverResize';
// import { Size } from '@bpchart/types'
// 以防嵌入的 d3-module 本身沒有 width/height屬性，所以給一個預設值
const defaultWidth = 800;
const defaultheight = 500;
const toListenerKeys = (attrs, listeners) => {
    // v2.x
    if (isVue2 && listeners) {
        return Object.keys(listeners);
    }
    // v3.x
    else if (isVue3 && attrs) {
        return Object.keys(attrs)
            .filter(d => {
            return d.substring(0, 2) === 'on';
        })
            .map(d => {
            return `${d.substring(2, 3).toLowerCase()}${d.substring(3, d.length)}`;
        });
    }
    else {
        return [];
    }
};
export default defineComponent({
    props: {
        module: {},
        params: {},
        data: {},
        datum: {},
        dataset: {},
        filter: {},
        width: {
            default: () => 0
        },
        height: {
            default: () => 0
        },
        forceFit: {
            default: () => true
        },
        zoom: {},
    },
    // emits: ['click', 'mouseover', 'mousemove', 'mouseout', 'dragStart', 'drag', 'dragEnd', 'zoom', 'resize'],
    // @ts-ignore
    setup(props, { emit, listeners }) {
        const attrs = useAttrs();
        const listenersKeys = toListenerKeys(attrs, listeners);
        const container = ref(null);
        const svg = ref(null);
        const containerWidth = computed(() => {
            let w = `${defaultWidth}px`;
            if (props.forceFit && !props.width) {
                w = '100%';
            }
            else if (typeof props.width === 'string') {
                w = props.width;
            }
            else if (typeof props.width === 'number' && props.width) {
                w = `${props.width}px`;
            }
            else {
                w = `${defaultWidth}px`;
            }
            return w;
        });
        const containerHeight = computed(() => {
            let h = `${defaultheight}px`;
            if (typeof props.height === 'string') {
                h = props.height;
            }
            else if (typeof props.height === 'number' && props.height) {
                h = `${props.height}px`;
            }
            return h;
        });
        const svgWidth = ref(defaultWidth);
        const svgHeight = ref(defaultheight);
        const { d3Module, createModule, eventHandler, debounceRender } = useD3Module({
            D3Module: props.module,
            props: props,
            listenersKeys,
        });
        // watch(props, () => {
        //   debounceRender()
        // })
        onMounted(() => {
            createModule(svg.value);
            eventHandler((eventName, data) => {
                emit(eventName, data);
            });
            // // container尺寸
            // if (props.forceFit) {
            //   containerWidth.value = '100%'
            // } else if ((container.value as any).clientWidth == 0) {
            //   containerWidth.value = `${d3Module.value.width || defaultWidth}px`
            // }
            // if ((container.value as any).clientHeight == 0) {
            //   containerHeight.value = `${d3Module.value.height || defaultheight}px`
            // }
            setTimeout(() => {
                // svg尺寸
                svgWidth.value = container.value.clientWidth;
                svgHeight.value = container.value.clientHeight;
                if (props.forceFit) {
                    // 監聽 container的 dom尺寸
                    const contentRect = useObserverResize(container.value);
                    watch(contentRect, () => {
                        svgWidth.value = contentRect.value.width;
                        svgHeight.value = contentRect.value.height;
                        d3Module.value.resize && d3Module.value.resize({ width: svgWidth.value, height: svgHeight.value });
                        debounceRender();
                        emit('resize', { width: svgWidth.value, height: svgHeight.value });
                    });
                }
                else {
                    debounceRender();
                }
                watch(props, () => debounceRender());
                watch(() => [props.width, props.height], () => {
                    emit('resize', { width: props.width, height: props.height });
                });
            });
        });
        return {
            d3Module,
            container,
            svg,
            containerWidth,
            containerHeight,
            svgWidth,
            svgHeight
        };
    }
});
