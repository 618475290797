// element-ui
// export const DEFAULT_COLORS = ['#1778F5', '#5ECED2', '#39BF4B', '#FFAD00', '#FF4935', '#909399']
// amchart
export const DEFAULT_COLORS = ['#67B7DC', '#6794DC', '#6771DC', '#8067DC', '#A367DC', '#C767DC', '#DC67CE', '#DC67AB', '#DC6788', '#DC6967', '#DC8C67', '#DCAF67'];
export const DEFAULT_CHART_WIDTH = 800;
export const DEFAULT_CHART_HEIGHT = 500;
export const DEFAULT_PADDING = {
    top: 50,
    right: 70,
    bottom: 50,
    left: 70
};
// axis
export const DEFAULT_ASIX_LINE_COLOR = '#e1e1e1';
export const DEFAULT_AXIS_LABEL_COLOR = '#7b7b7b';
