import * as d3 from 'd3';
import { DEFAULT_CHART_WIDTH, DEFAULT_CHART_HEIGHT, DEFAULT_COLORS } from '../defaults';
import { DEFAULT_CHART_BUBBLE_GROUP_PARAMS } from './defaults';
import { fitTextToCircle } from '../d3Utils';
import { calcAxisWidth, calcAxisHeight } from '../moduleUtils';
import TooltipFollowing from '../tooltip/TooltipFollowing';
// const forceStrength = 0.03
export default class ChartBubbleGroup {
    selection;
    params = DEFAULT_CHART_BUBBLE_GROUP_PARAMS;
    data = [];
    width = DEFAULT_CHART_WIDTH;
    height = DEFAULT_CHART_HEIGHT;
    sourceData = [];
    graphicSelection;
    graphicWidth = DEFAULT_CHART_WIDTH;
    graphicHeight = DEFAULT_CHART_HEIGHT;
    bubblesSelection;
    bubbleGroupR = 350; // 聚集的大泡泡半徑
    scaleBubbleR;
    scaleFillColor;
    // private TypeCenters: Map<string, { x: number; y: number; }> = new Map()
    force;
    groupMode = 'center';
    maxValue = 0;
    totalValue = 0;
    avgValue = 0;
    // private minWidth = 0
    highlightId = '';
    tooltip = undefined;
    mouseoverCallback = function () { };
    mousemoveCallback = function () { };
    mouseoutCallback = function () { };
    clickCallback = function () { };
    constructor(el, params) {
        this.selection = el;
        // this.params = {
        //   ...this.params,
        //   ...params,
        //   collisionSpacing: params.collisionSpacing ?? this.params.collisionSpacing
        // }
        this.graphicSelection = this.selection.append('g');
        this.tooltip = new TooltipFollowing(this.selection);
        // @Q@ 測試動畫用
        // let i = 0
        // setInterval(() => {
        //   let groupMode: any = 'center'
        //   if (i % 3 === 0) {
        //     groupMode = 'type'
        //   } else if (i % 3 === 1) {
        //     groupMode = 'rank'
        //   } else if (i % 3 === 2) {
        //     groupMode = 'center'
        //   }
        //   console.log(groupMode)
        //   this.setParams({
        //     groupMode
        //   })
        //   i ++
        // }, 2000)
    }
    resize({ width, height }) {
        // console.log('resize', { width, height })
        this.width = width;
        this.height = height;
        this.graphicWidth = calcAxisWidth(this.width, this.params.padding);
        this.graphicHeight = calcAxisHeight(this.height, this.params.padding);
        this.selection
            .attr('width', width)
            .attr('height', height);
        this.graphicSelection
            .attr('width', this.graphicWidth)
            .attr('height', this.graphicHeight);
        const minWidth = Math.min(...[this.graphicWidth, this.graphicHeight]);
        // 聚集的大泡泡半徑
        this.bubbleGroupR = (minWidth / 2);
        // // 計算r
        // this.calcBubbleR(this.data, this.bubbleGroupR)
        // if (this.groupMode == 'type') {
        //   this.splitBubbles()
        // } else if (this.groupMode == 'rank') {
        //   this.rankBubbles()
        // } else {
        //   this.groupBubbles()
        // }
        // this.render()
    }
    setData(sourceData) {
        this.sourceData = JSON.parse(JSON.stringify(sourceData));
        if (!this.data.length) {
            this.data = this.sourceData
                .map((d, i) => {
                return {
                    ...d,
                    x: Math.random() * this.graphicWidth,
                    y: Math.random() * this.graphicHeight,
                    r: 0, // 後面再計算
                };
            });
        }
        // 如果有舊資料的話，保留原本的座標及其他d3.force附加進來的欄位
        else {
            const LastDataMap = new Map(this.data.map(d => [d.id, d]));
            this.data = this.sourceData
                .map((d, i) => {
                const lastData = LastDataMap.get(d.id);
                let x = 0;
                let y = 0;
                if (lastData) {
                    x = lastData.x;
                    y = lastData.y;
                    return {
                        ...lastData,
                        value: d.value,
                        label: d.label,
                        type: d.type,
                        r: 0 // 後面再計算
                    };
                }
                else {
                    return {
                        ...d,
                        x: Math.random() * this.graphicWidth,
                        y: Math.random() * this.graphicHeight,
                        r: 0 // 後面再計算
                    };
                }
            });
        }
        this.data = this.data.sort((a, b) => b.value - a.value);
        this.maxValue = Math.max(...this.sourceData.map(d => d.value));
        this.totalValue = this.sourceData.map(d => d.value).reduce((prev, curr) => prev + curr, 0);
        this.avgValue = this.totalValue / this.sourceData.length;
        // console.log(this.data)
        // console.log('this.graphicSelection', this.graphicSelection)
        // this.resize({
        //   width: this.width,
        //   height: this.height
        // })
        // this.render()
        // // 計算r
        // this.calcBubbleR(this.data, this.bubbleGroupR)
        // if (this.groupMode == 'type') {
        //   this.splitBubbles()
        // } else if (this.groupMode == 'rank') {
        //   this.rankBubbles()
        // } else {
        //   this.groupBubbles()
        // }
    }
    render() {
        // 計算r
        this.calcBubbleR(this.data, this.bubbleGroupR);
        // console.log(this.data)
        let update = this.graphicSelection.selectAll("g")
            .data(this.data, (d) => d.id);
        let enter = update.enter()
            .append("g")
            .attr('cursor', 'pointer');
        enter
            .style('font-size', 12)
            .style('fill', '#ffffff')
            .attr("text-anchor", "middle")
            .attr("transform", (d) => {
            return `translate(${d.x},${d.y})`;
        });
        // .attr("cx", (d) => d.x)
        // .attr("cy", (d) => d.y)
        enter
            .append("circle")
            .attr("class", "node")
            // update.merge(enter)
            .attr("cx", 0)
            .attr("cy", 0)
            // .attr("r", 1e-6)
            .attr('fill', (d) => this.scaleFillColor(d.type));
        // .transition()
        // .duration(500)
        enter
            .append('text')
            .style('opacity', 0.8);
        update.exit().remove();
        this.bubblesSelection = update.merge(enter);
        this.bubblesSelection.select('circle')
            .transition()
            .duration(200)
            .attr("r", (d) => d.r)
            .attr('fill', (d) => this.scaleFillColor(d.type));
        this.bubblesSelection
            .each((d, i, g) => {
            const gSelection = d3.select(g[i]);
            let breakAll = true;
            if (d.label.length <= this.params.bubbleText.lineLengthMin) {
                breakAll = false;
            }
            fitTextToCircle(gSelection, {
                text: d.label,
                radius: d.r * this.params.bubbleText.fillRate,
                lineHeight: this.params.bubbleText.lineHeight,
                isBreakAll: breakAll
            });
        });
        this.setBubbleMouseEvent(this.bubblesSelection);
        this.force.nodes(this.data);
        // this.force!.alpha(1).restart();
        if (this.groupMode == 'type') {
            this.splitBubbles();
        }
        else if (this.groupMode == 'rank') {
            this.rankBubbles();
        }
        else {
            this.groupBubbles();
        }
    }
    remove() {
        this.selection.remove();
    }
    select() {
        return this.selection;
    }
    setParams(params) {
        this.params = {
            ...this.params,
            ...params,
            force: {
                ...this.params.force,
                ...params.force,
            },
            tooltipFollowing: {
                ...this.params.tooltipFollowing,
                ...params.tooltipFollowing
            },
            bubbleText: {
                ...this.params.bubbleText,
                ...params.bubbleText
            }
        };
        // console.log('params.groupMode', params.groupMode)
        if (params.highlightId != null) {
            this.setHighlight(params.highlightId);
        }
        if (params.groupMode != null) {
            this.params.groupMode = params.groupMode;
            this.groupMode = params.groupMode;
            // if (params.groupMode == 'type') {
            //   this.splitBubbles()
            // } else if (params.groupMode == 'rank') {
            //   this.rankBubbles()
            // } else {
            //   this.groupBubbles()
            // }
        }
        if (params.tooltipFollowing) {
            // this.tooltip = new TooltipFollowing(this.graphicSelection!, {
            //   templateHtml: this.params.tooltipFollowing!.templateHtml,
            //   insideBoxMode: this.params.tooltipFollowing!.insideBoxMode ?? true,
            //   type: this.params.tooltipFollowing!.type ?? 'black'
            // })
            this.tooltip.setParams({
                templateHtml: this.params.tooltipFollowing.templateHtml,
                insideBoxMode: this.params.tooltipFollowing.insideBoxMode ?? true,
                type: this.params.tooltipFollowing.type ?? 'black'
            });
        }
        this.graphicSelection
            .attr('transform', `translate(${this.params.padding.left}, ${this.params.padding.top})`);
        this.scaleFillColor = d3.scaleOrdinal()
            .domain(this.params.types)
            .range(this.params.colors);
        this.force = this.makeForce(this.params);
        this.force.stop();
    }
    on(actionName, callback) {
        if (actionName === 'click') {
            this.clickCallback = callback;
        }
        else if (actionName === 'mouseover') {
            this.mouseoverCallback = callback;
        }
        else if (actionName === 'mousemove') {
            this.mousemoveCallback = callback;
        }
        else if (actionName === 'mouseout') {
            this.mouseoutCallback = callback;
        }
        return this;
    }
    makeForce(params) {
        return d3.forceSimulation()
            .velocityDecay(params.force.velocityDecay)
            // .alphaDecay(0.2)
            .force("collision", d3.forceCollide()
            .radius(d => {
            // @ts-ignore
            return d.r + params.force.collisionSpacing;
        })
        // .strength(0.01)
        )
            .force("charge", d3.forceManyBody().strength((d) => {
            // @ts-ignore
            return -Math.pow(d.r, 2.0) * params.force.strength;
        }))
            // .force("x", d3.forceX().strength(forceStrength).x(this.graphicWidth / 2))
            // .force("y", d3.forceY().strength(forceStrength).y(this.graphicHeight / 2))
            .on("tick", () => {
            if (!this.bubblesSelection) {
                return;
            }
            this.bubblesSelection
                .attr("transform", (d) => {
                return `translate(${d.x},${d.y})`;
            });
            // .attr("cx", (d) => d.x)
            // .attr("cy", (d) => d.y)
        });
    }
    calcBubbleR(data, bubbleGroupR) {
        // 計算最大泡泡的半徑
        const getMaxR = () => {
            // 平均r（假想是正方型來計算的，比如說大正方型裡有4個正方型，則 r = width/Math.sqrt(4)/2）
            const avgR = bubbleGroupR / Math.sqrt(this.data.length);
            const avgSize = avgR * avgR * Math.PI;
            const sizeRate = avgSize / this.avgValue;
            const maxSize = this.maxValue * sizeRate;
            const maxR = Math.pow(maxSize / Math.PI, 0.5);
            const modifier = 0.75; // @Q@ 因為以下公式是假設泡泡是正方型來計算，所以畫出來的圖會偏大一些，這個數值是用來修正用的
            return maxR * modifier;
        };
        this.scaleBubbleR = d3.scalePow()
            .domain([0, this.maxValue])
            .range([0, getMaxR()])
            .exponent(0.5); // 0.5為取平方根
        // in-placed
        data.forEach(d => {
            const r = this.scaleBubbleR(d.value);
            d.r = this.highlightId == d.id ? r + 10 : r;
        });
    }
    setBubbleMouseEvent(selection) {
        // console.log(selection)
        selection
            .on('mouseover', (d, i, g) => {
            this.tooltip.setDatum({
                data: d,
                x: d3.event.clientX,
                y: d3.event.clientY
            });
            // this.setParams({
            //   highlightId: d.id
            // })
            this.setHighlight(d.id);
            this.mouseoverCallback(d);
        })
            .on('mousemove', (d, i, g) => {
            this.tooltip.setDatum({
                x: d3.event.clientX,
                y: d3.event.clientY
            });
            this.mousemoveCallback(d);
        })
            .on('mouseout', (d, i, g) => {
            this.tooltip.remove();
            // this.setParams({
            //   highlightId: ''
            // })
            this.setHighlight('');
            this.mouseoutCallback(d);
        })
            .on('click', (d, i, g) => {
            this.clickCallback(d);
        })
            .call(this.drag());
    }
    drag() {
        return d3.drag()
            .on("start", (d) => {
            if (!d3.event.active) {
                this.force.alpha(1).restart();
            }
            d.fx = d.x;
            d.fy = d.y;
        })
            .on("drag", (d) => {
            if (!d3.event.active) {
                this.force.alphaTarget(0);
            }
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        })
            .on("end", (d) => {
            d.fx = null;
            d.fy = null;
        });
    }
    splitBubbles() {
        const step = this.graphicWidth / (this.params.types.length + 1); // 距離
        const TypeCenters = new Map(this.params.types.map((type, index) => {
            return [type, {
                    x: step * (index + 1),
                    y: this.graphicHeight / 2
                }];
        }));
        this.force
            .force('x', d3.forceX().strength(this.params.force.strength).x((d) => TypeCenters.get(d.type).x))
            .force('y', d3.forceY().strength(this.params.force.strength).y(this.graphicHeight / 2));
        this.force.alpha(3).restart();
    }
    // private nodeTypePos (d: any) {
    //   console.log(d)
    //   console.log(this.TypeCenters.get(d.type)!)
    //   const typeCenter = this.TypeCenters.get(d.type)!
    //   return typeCenter ? typeCenter.x : 0
    // }
    groupBubbles() {
        this.force
            .force('x', d3.forceX().strength(this.params.force.strength).x(this.graphicWidth / 2))
            .force('y', d3.forceY().strength(this.params.force.strength).y(this.graphicHeight / 2));
        this.force.alpha(1).restart();
    }
    rankBubbles() {
        if (!this.data.length) {
            return;
        }
        const padding = 100;
        const margin = 10;
        const maxW = this.graphicWidth - padding * 2;
        // const lineHeight = this.maxR * 2
        let lastX = padding;
        let lastY = padding + this.data[0].r;
        let lastR = 0;
        let lastLineFirstR = this.data[0].r;
        // let lastLineNo = 0
        let lineNo = 0;
        let rankPosition = this.data.map((d, i) => {
            // lastX = lastX + (d.r * 2.5)
            // let lineNo = Math.ceil(lastX / maxW)
            // let x = margin + lastX % maxW
            // let y = margin + lineNo * lineHeight
            let x = lastX + lastR + margin + d.r;
            let y = lastY;
            // const lineNo = Math.ceil(x / maxW)
            // 換行
            if (x > maxW) {
                x = padding + d.r;
                y += lastLineFirstR + margin + d.r;
                lastLineFirstR = d.r;
                lineNo++;
                // lastLineNo = lineNo
            }
            lastX = x;
            lastY = y;
            lastR = d.r;
            return { x, y };
        });
        // console.log('rankPosition', rankPosition)
        this.force
            .force('x', d3.forceX().strength(this.params.force.strength).x((d, i) => {
            return rankPosition[i].x;
        }))
            .force('y', d3.forceY().strength(this.params.force.strength).y((d, i) => {
            return rankPosition[i].y;
        }));
        this.force.alpha(3).restart();
    }
    setHighlight(highlightId) {
        this.params.highlightId = highlightId;
        this.highlightId = highlightId;
        // this.calcBubbleR(this.data, this.bubbleGroupR)
        // this.render()
        if (this.highlightId == '') {
            this.bubblesSelection
                .style('opacity', 1);
        }
        else {
            this.bubblesSelection
                .style('opacity', (d) => {
                if (d.id == this.highlightId) {
                    return 1;
                }
                else {
                    return 0.5;
                }
            });
        }
    }
}
