import * as d3 from 'd3';
import { Padding } from './types';
export const calcAxisWidth = (width, padding) => {
    return width - padding.left - padding.right;
};
export const calcAxisHeight = (height, padding) => {
    return height - padding.top - padding.bottom;
};
export const makeAxisPointScale = ({ axisLabels, axisWidth, padding = 0.5, reverse = false }) => {
    let range = [0, axisWidth];
    if (reverse) {
        range = [axisWidth, 0];
    }
    return d3.scalePoint()
        .domain(axisLabels)
        .range(range)
        .padding(0.5);
};
// export const makeAxisPointScale = (axisLabels: string[], axisWidth: number, reverse: boolean = false) => {
//   let range = []
//   if (reverse) {
//     range = [axisWidth, 0]
//   } else {
//     range = [0, axisWidth]
//   }
//   return d3.scalePoint()
//       .domain(axisLabels)
//       .range([0, axisWidth])
//       .padding(0.5)
// }
// export const makeYPointScale = (axisLabels: string[], axisHeight: number) => {
//   return d3.scalePoint()
//       .domain(axisLabels)
//       .range([axisHeight, 0])
//       .padding(0.5)
// }
export const makeAxisTimeScale = (DateList, axisWidth, scalePadding = 0) => {
    // scaleTime 沒有 .padding()方法，所以手動計算
    const padding = axisWidth / (DateList.length / scalePadding);
    return d3.scaleTime()
        .domain([DateList[0], DateList[DateList.length - 1]])
        .range([padding, axisWidth - padding]);
};
export const makeAxisLinearScale = ({ maxValue, minValue, axisWidth, domainMinValue = undefined, domainMaxValue = undefined, domainMinRange = undefined, domainMaxRange = undefined, reverse = false }) => {
    let min = minValue;
    let max = maxValue;
    if (domainMinValue != null) {
        const _min = domainMinValue;
        min = _min < min ? _min : min;
    }
    if (domainMinRange != null) {
        // 用最小值在比例尺上的位置(參數domainRange)，反推比例尺的最小值 (d3.scale.domain)
        const _min = maxValue - (maxValue - minValue) / (1 - domainMinRange);
        min = _min < min ? _min : min;
    }
    if (domainMaxValue != null) {
        const _max = domainMaxValue;
        max = _max > max ? _max : max;
    }
    if (domainMaxRange != null) {
        // 用最大在比例尺上的位置(參數domainRange)，反推比例尺的最大 (d3.scale.domain)
        const _max = maxValue / domainMaxRange;
        max = _max > max ? _max : max;
    }
    // 避免值全為0時座標被算到中間
    if (min == 0 && max == 0) {
        max = 1;
    }
    let domain = [min, max];
    if (reverse) {
        domain = [max, min];
    }
    return d3.scaleLinear()
        .domain(domain)
        .range([0, axisWidth]);
};
// export const makeAxisLinearScale = ({ maxValue, minValue, axisWidth, domainMinValue, domainMaxValue, domainMinRange, domainMaxRange }: {
//   maxValue: number
//   minValue: number
//   axisWidth: number
//   domainMinValue?: number
//   domainMaxValue?: number
//   domainMinRange?: number
//   domainMaxRange?: number
// }) => {
//   let min = minValue
//   let max = maxValue
//   if (domainMinValue != null) {
//     const _min = domainMinValue
//     min = _min < min ? _min : min
//   }
//   if (domainMinRange != null) {
//     // 用最小值在比例尺上的位置(參數domainRange)，反推比例尺的最小值 (d3.scale.domain)
//     const _min = maxValue - (maxValue - minValue) / (1 - domainMinRange)
//     min = _min < min ? _min : min
//   }
//   if (domainMaxValue != null) {
//     const _max = domainMaxValue
//     max = _max > max ? _max : max
//   }
//   if (domainMaxRange != null) {
//     // 用最大在比例尺上的位置(參數domainRange)，反推比例尺的最大 (d3.scale.domain)
//     const _max = maxValue / domainMaxRange
//     max = _max > max ? _max : max
//   }
//   return d3.scaleLinear()
//     .domain([min, max]) // 因為要由下往上所以反過來
//     .range([0, axisWidth])
// }
// export const makeYLinearScale = ({ maxValue, minValue, axisHeight, domainMinValue, domainMaxValue, domainMinRange, domainMaxRange }: {
//   maxValue: number
//   minValue: number
//   axisHeight: number
//   domainMinValue?: number
//   domainMaxValue?: number
//   domainMinRange?: number
//   domainMaxRange?: number
// }) => {
//   let min = minValue
//   let max = maxValue
//   if (domainMinValue != null) {
//     const _min = domainMinValue
//     min = _min < min ? _min : min
//   }
//   if (domainMinRange != null) {
//     // 用最小值在比例尺上的位置(參數domainRange)，反推比例尺的最小值 (d3.scale.domain)
//     const _min = maxValue - (maxValue - minValue) / (1 - domainMinRange)
//     min = _min < min ? _min : min
//   }
//   if (domainMaxValue != null) {
//     const _max = domainMaxValue
//     max = _max > max ? _max : max
//   }
//   if (domainMaxRange != null) {
//     // 用最大在比例尺上的位置(參數domainRange)，反推比例尺的最大 (d3.scale.domain)
//     const _max = maxValue / domainMaxRange
//     max = _max > max ? _max : max
//   }
//   return d3.scaleLinear()
//     .domain([max, min]) // 因為要由下往上所以反過來
//     .range([0, axisHeight])
// }
export const makeAxisQuantizeScale = ({ axisLabels, axisWidth, reverse = false }) => {
    const rangePadding = 0;
    let range = [];
    if (reverse) {
        range = axisLabels.map((d, i) => axisLabels.length - 1 - i);
    }
    else {
        range = axisLabels.map((d, i) => i);
    }
    return d3.scaleQuantize()
        .domain([-rangePadding, axisWidth + rangePadding])
        .range(range);
};
// export const makeXQuantizeScale = (axisLabels: string[] | Date[], axisWidth: number) => {
//   const rangePadding = 0
//   return d3.scaleQuantize<number>()
//     .domain([- rangePadding, axisWidth + rangePadding])
//     .range(axisLabels.map((d, i) => i))
// }
// export const makeYQuantizeScale = (axisLabels: string[] | Date[], axisHeight: number) => {
//   const rangePadding = 0
//   return d3.scaleQuantize<number>()
//     .domain([- rangePadding, axisHeight + rangePadding])
//     .range(axisLabels.map((d, i) => axisLabels.length - 1 - i))
// }
export const makeColorScale = (ids, colors) => {
    return d3.scaleOrdinal()
        .domain(ids.map((d) => d))
        .range(ids.map((d, i) => colors[i]));
};
// 圓半徑比例尺（用圓面積的比例來計算）
export const makeCircleRScale = (maxValue, maxR) => {
    return d3.scalePow()
        .domain([0, maxValue])
        .range([0, maxR])
        .exponent(0.5); // 0.5為取平方根
};
export const makeArc = ({ axisWidth, innerRadius, outerRadius, padAngle, cornerRadius }) => {
    const arcScale = d3.scaleLinear()
        .domain([0, 1])
        .range([0, axisWidth / 2]);
    const _outerRadius = arcScale(outerRadius);
    return d3.arc()
        .innerRadius(arcScale(innerRadius))
        .outerRadius(_outerRadius)
        .padAngle(padAngle)
        .padRadius(_outerRadius)
        .cornerRadius(cornerRadius);
};
