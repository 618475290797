import { ref, watch } from 'vue-demi';
import * as d3 from 'd3';
import { debounce } from '../utils';
export default function useD3Module({ D3Module, props, listenersKeys }) {
    const d3Module = ref(undefined);
    const refEl = ref();
    // render
    const render = () => {
        if (d3Module.value && d3Module.value.render) {
            ;
            d3Module.value.render();
            // console.log('render')
        }
    };
    const debounceRender = debounce(render);
    const setParams = (value) => value
        && d3Module.value.setParams
        && d3Module.value.setParams(value);
    const setData = (value) => value
        && d3Module.value.setData
        && d3Module.value.setData(value);
    const setDataset = (value) => value
        && d3Module.value.setDataset
        && d3Module.value.setDataset(value);
    const setDatum = (value) => value
        && d3Module.value.setDatum
        && d3Module.value.setDatum(value);
    const filter = (value) => value
        && d3Module.value.filter
        && d3Module.value.filter(value);
    const resize = ({ width, height }) => {
        d3Module.value.resize
            && d3Module.value.resize({
                width: width && typeof width === 'number' ? width : d3Module.value.width,
                height: height && typeof height === 'number' ? height : d3Module.value.height
            });
    };
    const zoom = (value) => {
        if (value
            && d3Module.value.zoom
            && d3Module.value.transform
            && d3Module.value.transformZoom
            && d3Module.value.initZoom) {
            ;
            d3Module.value.initZoom(d3Module.value.zoom);
            d3Module.value.transformZoom(value);
        }
    };
    watch(() => props.params, (value) => {
        setParams(value || {});
    });
    watch(() => props.data, (value) => {
        setData(value);
    });
    watch(() => props.dataset, (value) => {
        setDataset(value);
    });
    watch(() => props.datum, (value) => {
        setDatum(value);
    });
    watch(() => props.filter, (value) => {
        filter(value);
    });
    watch(() => [props.width, props.height], ({ width, height }) => {
        (props.width || props.height) && resize({ width, height });
    });
    watch(() => props.zoom, (value) => {
        zoom(value);
    });
    // watch(() => [props.params, props.data, props.dataset, props.datum, props.filter, props.width, props.height, props.forceFit, props.zoom], () => {
    //   debounceRender()
    // })
    return {
        d3Module: d3Module,
        debounceRender,
        createModule(el) {
            refEl.value = el;
            d3Module.value = new D3Module(d3.select(el), props.params || {});
            // console.log('filter', props.filter)
            setParams(props.params || {});
            setData(props.data);
            setDataset(props.dataset);
            setDatum(props.datum);
            filter(props.filter);
            resize({
                width: props.width,
                height: props.height
            });
            zoom(props.zoom);
        },
        eventHandler(callback) {
            // 事件處理
            let eventCallback = callback;
            listenersKeys.forEach(key => {
                if (d3Module.value.on != undefined) {
                    d3Module.value.on(key, (d) => {
                        if (eventCallback) {
                            console.log(key, d);
                            eventCallback(key, d);
                        }
                    });
                }
            });
        }
    };
}
