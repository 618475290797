import * as d3 from 'd3';
import { DEFAULT_TOOLTIP } from './defaults';
// import { debounce } from '../utils'
// // @Q@ legacy code
// export interface RenderDatum {
//   _sourceData: any; // 對應sourceData
// }
// export type TooltipParams = {
//   // insideBoxMode: boolean;
//   type?: string;
//   // template: string;
//   offsetX?: number;
//   offsetY?: number;
//   templateHtml? (data: any): string;
// }
// export type TooltipRenderParams = {
//   // insideBoxMode: boolean;
//   type: string;
//   // template: string;
//   offsetX: number;
//   offsetY: number;
//   templateHtml (data: any): string;
// }
// export type TooltipSourceDatum = {
//   x: number;
//   y: number;
//   // data?: { [key: string]: any } // 如無輸入值則預設使用前次資料
//   data?: any // 如無輸入值則預設使用前次資料
// }
// export type TooltipRenderDatum = RenderDatum & {
//   _sourceData: TooltipSourceDatum;
//   x: number;
//   y: number;
//   contentHtml: string;
// }
export default class Tooltip {
    selection;
    params = DEFAULT_TOOLTIP;
    data; // @Q@ 尚未重構為新的寫法
    datum = {
        x: -1,
        y: -1,
        data: null
    };
    renderData = {
        _sourceData: {
            x: -1,
            y: -1
        },
        x: -1,
        y: -1,
        contentHtml: ''
    };
    setTooltipStyle = (s) => { };
    // protected tooltipSelection: (d3.Selection<HTMLElement, any, any, unknown> | undefined) = undefined
    // protected debounceRender = debounce((d: any) => {})
    constructor(selection, params) {
        this.selection = selection;
        this.params = {
            ...this.params,
            ...params
        };
        if (this.params.type === 'black') {
            this.setTooltipStyle = (s) => {
                s
                    .style('pointer-events', 'none')
                    .style('white-space', 'nowrap')
                    .style('padding', '10px 10px')
                    .style('background', '#333333')
                    .style('border-radius', '5px')
                    .style('opacity', 0.9)
                    .style('color', 'white')
                    .style('font-size', '14px')
                    // .style('font-weight', 'normal')
                    .style('text-align', 'left');
            };
        }
        else if (this.params.type === 'white') {
            this.setTooltipStyle = (s) => {
                s
                    .style('pointer-events', 'none')
                    .style('white-space', 'nowrap')
                    .style('padding', '10px 15px')
                    // .style('background', '#ffffff')
                    .style('background-color', 'rgba(255,255,255,.8)')
                    // .style('border', '1px solid rgba(204,204,204,0.8)')
                    .style('border', '1px solid rgba(152,152,152,0.5)')
                    .style('border-radius', '5px')
                    // .style('opacity', 0.8)
                    .style('color', 'black')
                    .style('font-size', '12px')
                    .style('line-height', '20px')
                    // .style('font-weight', 'normal')
                    .style('text-align', 'left');
                // .style('box-shadow', '2px 2px 5px 0px rgba(204,204,204,0.8)')
                // .style('box-shadow', '0px 0px 15px 2px rgba(152,152,152,0.25)')
                // .style('backdrop-filter', 'blur(4px)')
            };
        }
        // this.debounceRender = debounce(this.renderTooltip.bind(this))
    }
    // private setTooltipStyle (type: string) {
    //   selection.selectAll('div')
    //     .data()
    // }
    // setParams (params: TooltipParams) {
    // }
    setDatum(sourceData) {
        if (sourceData?.data) {
            this.datum.data = sourceData.data;
            this.renderData.contentHtml = this.params.templateHtml
                ? this.params.templateHtml(sourceData.data)
                : '';
        }
        if (!this.renderData.contentHtml) {
            return; // 無資料不顯示
        }
        const x = sourceData?.x ?? d3.event.clientX;
        const y = sourceData?.y ?? d3.event.clientY;
        this.datum.x = x;
        this.datum.y = y;
        this.renderData.x = x;
        this.renderData.y = y;
        this.render();
    }
    render = () => {
        this.renderTooltip([this.renderData]);
    };
    select() {
        return this.selection;
    }
}
