import { DEFAULT_COLORS, DEFAULT_PADDING } from '@bpchart/d3-modules/defaults';
export const DEFAULT_CHART_BUBBLE_GROUP_PARAMS = {
    types: [],
    colors: DEFAULT_COLORS,
    // collisionSpacing: 2,
    highlightId: undefined,
    groupMode: 'center',
    padding: DEFAULT_PADDING,
    tooltipFollowing: {
        templateHtml: (data) => `
      <div>${data.label}</div>
      <div>${data.value}</div>
    `,
        insideBoxMode: true,
        type: 'black'
    },
    force: {
        strength: 0.03,
        velocityDecay: 0.2,
        collisionSpacing: 2 // 泡泡間距
    },
    bubbleText: {
        fillRate: 0.6,
        lineHeight: 12,
        lineLengthMin: 4
    }
};
