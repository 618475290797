import * as d3 from 'd3';
// @Q@ 因ie上svg不支援innerHTML，所以用這個function取代
export function parseSvg(htmlString) {
    const div = document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
    div.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg">' + htmlString + '</svg>';
    const frag = document.createDocumentFragment();
    while (frag && div?.firstChild?.firstChild)
        frag.appendChild(div.firstChild.firstChild);
    return frag;
}
// svg加入html @Q@ 因ie上svg不支援innerHTML，所以用這個function取代
export function svgHtml(selection, htmlString) {
    // const parseSVG = (s: string) => {
    //   const div = document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
    //   div.innerHTML= '<svg xmlns="http://www.w3.org/2000/svg">'+ s +'</svg>';
    //   const frag = document.createDocumentFragment()
    //   while (frag && div?.firstChild?.firstChild)
    //       frag.appendChild(div.firstChild.firstChild);
    //   return frag;
    // }
    // 刪除現有子節點
    const node = selection.node();
    while (node.hasChildNodes()) {
        node.removeChild(node.firstChild);
    }
    // 加入dom
    selection.node().appendChild(parseSvg(htmlString));
}
export function fitTextToCircle(selection, { text, radius, lineHeight, isBreakAll = false, limit = 0 }) {
    if (selection == null || text == null) {
        console.error("selection or text is not defined");
        return;
    }
    if (radius == null) {
        const getBox = selection.node().getBBox();
        radius = getBox.width / 2;
    }
    function getWords(text) {
        let words;
        if (isBreakAll) {
            words = text.split('');
        }
        else {
            words = text.split(/\s+/g); // To hyphenate: /\s+|(?<=-)/
        }
        if (!words[words.length - 1])
            words.pop();
        if (!words[0])
            words.shift();
        return words;
    }
    // 省略文章字數
    function ellipisText(text, limit) {
        if (text && limit) {
            if (text.length > limit) {
                text = text.substring(0, limit) + "..."; // 超過字數以"..."取代
            }
        }
        return text;
    }
    function measureWidth(text) {
        const context = document.createElement("canvas").getContext("2d");
        // return text => context.measureText(text).width
        return context?.measureText(text)?.width ?? 0;
    }
    function getTargetWidth(text) {
        const m = measureWidth(text.trim());
        const result = Math.sqrt(m * lineHeight);
        return result;
        // return(
        // Math.sqrt(measureWidth(text.trim()) * lineHeight)
        // )
    }
    function getLines(words, targetWidth) {
        let line = { width: 0, text: '' };
        let lineWidth0 = Infinity;
        const lines = [];
        let space = " ";
        if (isBreakAll) {
            space = "";
        }
        for (let i = 0, n = words.length; i < n; ++i) {
            const lineText1 = (line.text ? line.text + space : '') + words[i];
            const lineWidth1 = measureWidth(lineText1);
            if ((lineWidth0 + lineWidth1) / 2 < targetWidth) {
                line.width = lineWidth0 = lineWidth1;
                line.text = lineText1;
            }
            else {
                lineWidth0 = measureWidth(words[i]);
                line = { width: lineWidth0, text: words[i] };
                lines.push(line);
            }
        }
        return lines;
    }
    function getTextRadius(lines) {
        let radius = 0;
        for (let i = 0, n = lines.length; i < n; ++i) {
            const dy = (Math.abs(i - n / 2 + 0.5) + 0.5) * lineHeight;
            const dx = lines[i].width / 2;
            radius = Math.max(radius, Math.sqrt(dx ** 2 + dy ** 2));
        }
        return radius;
    }
    function draw(selection, text) {
        if (limit > 0)
            text = ellipisText(text, limit);
        const words = getWords(text);
        const targetWidth = getTargetWidth(text);
        const lines = getLines(words, targetWidth);
        const textRadius = getTextRadius(lines);
        let t = selection.select("text");
        if (!t.size()) {
            t = selection.append("text");
        }
        t.attr("transform", `translate(${0},${0}) scale(${radius / textRadius})`);
        const tspan = t.selectAll("tspan")
            .data(lines);
        tspan.enter()
            .append("tspan")
            .attr("x", 0)
            .merge(tspan)
            .attr("y", (d, i) => (i - lines.length / 2 + 0.8) * lineHeight)
            .text((d) => d.text);
        tspan.exit().remove();
        return selection.node();
    }
    draw(selection, text);
}
/**
 * 用於將 svg `<text>` 元素中的文字斷行，1.設定中文及英文的每行字數限制，2.設定是否須將文字縮放以符合外框。
 *
 * @param `selection` 第一個參數須為<text>的d3 selection。
 *
 * @param `perLineEn` 英數字串(英文、數字、空格、減號-)的每行字數限制，預設為7個字元。
 * @param `perLineZh` 其他文字字串的每行字數限制，預設為5個字元。
 * @param `lineLimit` 最高行數，若超過最高行數，其餘文字將被'...'取代，預設為3行。
 * @param `radius` 外框寬，非必須，若提供此參數，則文字將會依此值縮放。
 */
export function wrapTextToCircle(selection, { perLineZh = 5, perLineEn = 7, lineLimit = 3, radius, }) {
    selection.each(function () {
        let tagText = d3.select(this);
        let wordRest = tagText.text();
        if (!wordRest) {
            console.error("[bp-chart: wrapTextToCircle] Can't get words: The <text> tag missing text node.");
        }
        tagText.text('');
        //依據字數限制換行
        const lineH = parseInt(tagText.style('font-size')) + 2;
        //判斷中英文
        const regEn = /^[A-Za-z0-9\s\-]*$/;
        const perLine = regEn.test(wordRest) ? perLineEn : perLineZh;
        let i = 0;
        //猜行數，用於計算tspan的y位置
        const assumeLine = wordRest.length / perLine > lineLimit ? lineLimit : Math.ceil(wordRest.length / perLine);
        const startY = -(assumeLine - 1) * (lineH / 2) + 4;
        while (wordRest.length > 0 && i < lineLimit) {
            //以空格段行，或單字長時以字數限制段行
            let thisLine = wordRest.slice(0, perLine + 1);
            let pos = thisLine.lastIndexOf(' ') > -1 ? thisLine.lastIndexOf(' ') : perLine;
            wordRest = wordRest.slice(wordRest[pos] === ' ' ? pos + 1 : pos);
            tagText.append('tspan')
                .text(i === lineLimit - 1 && wordRest.length > 0 ? thisLine.slice(0, pos - 1) + '...' : thisLine.slice(0, pos))
                .attr('y', startY + lineH * i)
                .attr('x', 0);
            i++;
        }
        //依直徑縮放文字
        if (radius !== undefined) {
            const box = tagText.node().getBBox();
            let textW = Math.max(box.width, box.height);
            let textRate = (radius * 2 - 5) / textW;
            if (typeof textRate !== 'number' || textRate <= 0) {
                console.error(`[bp-chart: wrapTextToCircle] Invalid value for 'radius' or '<text> box size'. Check out these value:
    - radius: ${radius},
    - <text> width: ${box.width},
    - <text> height: ${box.height}.`);
                return;
            }
            tagText.transition().attr('transform', `scale(${textRate})`);
        }
    });
}
export function getHtmlElementSize(selection) {
    try {
        return selection.node().getBoundingClientRect();
    }
    catch (e) {
        throw new Error(e);
    }
}
export function getSvgGElementSize(selection) {
    try {
        return selection.node().getBBox();
    }
    catch (e) {
        throw new Error(e);
    }
}
// 字串轉日期（避免瀏覽器轉換為UTC相對時間）
export function parseLocalDate(date, formatter) {
    // d3.timeParse
    if (formatter) {
        const parseTime = d3.timeParse(formatter);
        return parseTime(date);
    }
    // 當日期字串不包含時間 e.g. 2021-01-01
    else if (date.length < 14) {
        return new Date(new Date(date).setHours(0, 0, 0, 0));
    }
    return new Date(date);
}
export const findStartDate = (DateList, startDate) => {
    let _FilterStartDate = DateList[0];
    if (startDate) {
        if (typeof startDate === 'string') {
            _FilterStartDate = parseLocalDate(startDate);
        }
        else if (startDate instanceof Date) {
            _FilterStartDate = startDate;
        }
    }
    const startIndex = DateList.findIndex(d => d >= _FilterStartDate);
    const StartDate = DateList[startIndex];
    return {
        StartDate: StartDate ? StartDate : DateList[0],
        startIndex
    };
};
export const findEndDate = (DateList, endDate) => {
    let _FilterEndDate = DateList[DateList.length - 1];
    if (endDate) {
        if (typeof endDate === 'string') {
            _FilterEndDate = parseLocalDate(endDate);
        }
        else if (endDate instanceof Date) {
            _FilterEndDate = endDate;
        }
    }
    const countdownIndex = Object.assign([], DateList)
        .reverse()
        .findIndex(d => d <= _FilterEndDate);
    const endIndex = DateList.length - 1 - countdownIndex;
    const EndDatum = DateList[endIndex];
    return {
        EndDate: EndDatum ? EndDatum : DateList[DateList.length - 1],
        endIndex
    };
};
export const parseTickFormatValue = (value, tickFormat) => {
    if (tickFormat instanceof Function == true) {
        return tickFormat(value);
    }
    return d3.format(tickFormat)(value);
};
export const parseDateTickFormatValue = (value, tickFormat) => {
    if (tickFormat instanceof Function == true) {
        return tickFormat(value);
    }
    return d3.timeFormat(tickFormat)(value);
};
export const makeDateList = (dates, xTickFormat) => {
    if (!dates.length) {
        return { xLabels: [], DateList: [] };
    }
    // const formatter = d3.timeFormat(xTickFormat)
    const parseDate = typeof dates[0] === 'string' ? (d) => parseLocalDate(d)
        : dates[0] instanceof Date ? (d) => d
            : (d) => d;
    const DateList = dates.map(d => parseDate(d));
    return {
        DateList,
        xLabels: DateList.map(d => parseDateTickFormatValue(d, xTickFormat))
    };
};
